<template>
    <b-form class="card bs-5" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" @failure="formFailure"
            :save-params="{api_key: currentUser.api_key}">

        <div class="row">
            <validated-input class="col-6" label="Course id" v-model="model.course_id"
                             :rules="rules.course_id" :disabled="loading"/>
            <validated-input class="col-6" label="Contact Person" v-model="model.contact_person"
                             :rules="rules.contact_person" :disabled="loading"/>
            <validated-input class="col-12" label="Title" v-model="model.title"
                             :rules="rules.title" :disabled="loading"/>
        </div>

        <div class="row">

            <file-input class="c-file-input col-4" label="Image" v-model="model.image" :disabled="loading"/>

            <validated-input class="col-4" label="Class Type" v-model="model.class_type"
                             :rules="rules.class_type" :disabled="loading"/>

            <validated-input class="col-4" label="Contact E-Mail" v-model="model.contact_email" type="email"
                             :rules="{required:true}" :disabled="loading">
            </validated-input>

        </div>
        <div class="row">
            <div class="col">
                <image-info :width="1110" :height="430"/>
            </div>
        </div>

        <div class="row">

            <validated-input class="col-4" label="Duration" v-model="model.duration"
                             :rules="rules.duration" :disabled="loading"/>

            <validated-input class="col-4" label="Course Fee" name="Course Fee" v-model="model.course_fee"
                             type="number" :rules="rules.course_fee" :disabled="loading">
                <template #prepend>
                    <i class="fa fa-rupee"></i>
                </template>
            </validated-input>

            <validated-input class="col-4" label="Tax Percentage" v-model="model.tax_percentage" type="number"
                             :rules="rules.tax_percentage" :disabled="loading">
                <template #prepend>
                    <span style="font-weight: bold" class="mr-2">%</span>
                </template>
            </validated-input>

        </div>

        <div class="row">
            <validated-textarea class="col-12" label="Content" v-model="model.content"
                                :rules="rules.content" :disabled="loading"/>
        </div>

        <div class="row">
            <validated-textarea class="col-12" label="Description 1 (website)" name="Description 1"
                                v-model="model.description_1"
                                :rules="rules.description_1" :disabled="loading"/>

            <validated-textarea class="col-12" label="Description 2 (website)" name="Description 2"
                                v-model="model.description_2"
                                :rules="rules.description_2" :disabled="loading"/>

            <validated-textarea class="col-12" label="Description 3 (website)" name="Description 3"
                                v-model="model.description_3"
                                :rules="rules.description_3" :disabled="loading"/>
        </div>

        <div class="row">
            <validated-input class="col-12" label="Content 1 (Landing Page Right side)" name="Content 1"
                             v-model="model.content_1"
                             :rules="rules.content_1" :disabled="loading"/>

            <validated-input class="col-12" label="Content 2 (Landing Page Right side)" name="Content 2"
                             v-model="model.content_2"
                             :rules="rules.content_2" :disabled="loading"/>
        </div>

        <div class="row">
            <validated-input class="col-12" label="Content 3 (Landing Page Right side)" name="Content 3"
                             v-model="model.content_3"
                             :rules="rules.content_3" :disabled="loading"/>

            <validated-input class="col-12" label="Content 4 (Landing Page Left side)" name="Content 4"
                             v-model="model.content_4"
                             :rules="rules.content_4" :disabled="loading"/>
        </div>

        <div class="row">
            <validated-input class="col-12" label="Content 5 (Landing Page Left side)" name="Content 5"
                             v-model="model.content_5"
                             :rules="rules.content_5" :disabled="loading"/>

            <validated-input class="col-12" label="Content 6 (Landing Page Left side)" name="Content 6"
                             v-model="model.content_6"
                             :rules="rules.content_6" :disabled="loading"/>
        </div>

        <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
             loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'AddTraining',
    computed : { ...mapGetters(['currentUser']) },
    data () {
        return {
            addUrl : urls.admin.event.training.addEdit,
            rules  : {
                course_id : {
                    required : true
                },
                title : {
                    required : true
                },
                class_type : {
                    required : true
                },
                course_fee : {
                    required : true
                },
                total_fee : {
                    required : true
                },
                tax_percentage : {
                    required : true
                },
                image : {
                    required : true
                },
                fees_text : {
                    required : true
                },
                duration : {
                    required : true
                },
                content : {
                    required : true,
                    min      : 100,
                    max      : 172
                },
                content_1 : {
                    required : false
                },
                content_2 : {
                    required : false
                },
                content_3 : {
                    required : false
                },
                content_4 : {
                    required : false
                },
                content_5 : {
                    required : false
                },
                contact_person : {
                    required : true
                },
                contact_email : {
                    required : true
                }
            },
            class_type : [
                { label : 'Online', value : 'Online' },
                { label : 'Offline', value : 'Offline' }
            ]
        };
    },

    methods : {
        formSuccess (response) {
            this.$notify('Successfully Added Training..!', 'Success',
                {
                    type : 'success'
                }
            );
            const id = response.data.id;
            this.$router.push({ path : '/training/' + id + '/details/' });
        },

        formFailure (response) {
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Invalid Authentication..!', 'Permission Denied',
                    {
                        type : 'warning'
                    }
                );
                this.$router.push({ path : '/login/' });
            }
        }
    }
};
</script>

<style scoped>

</style>
